import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Divider, Stack, Typography, alpha, useTheme, useMediaQuery } from "@mui/material";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import useFetchProvidersList from "@/hooks/fetchers/useProvider";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const menus: Menu[] = [
  {
    key: "wallets",
    label: "กระเป๋าเงิน",
    icon: "solar:wallet-broken",
  },
  // {
  //   key: "historyAll",
  //   label: "ประวัติธุรกรรม",
  //   icon: "mdi:history",
  // },
  // {
  //   key: "transferWallet",
  //   label: "โยกเงิน",
  //   icon: "solar:round-transfer-horizontal-broken",
  // },
  {
    key: "affiliate",
    label: "แนะนำเพื่อน",
    icon: "mdi:invite",
  },
  {
    key: "refund",
    label: "คืนยอดเสีย",
    icon: "gridicons:refund",
  },
  {
    key: "cashbackTurnover",
    label: "คืนยอดเล่น",
    icon: "gridicons:money",
  },
  {
    key: "cashbackWinlose",
    label: "คืนยอดเสีย",
    icon: "gridicons:refund",
  },
  {
    key: "promotion",
    label: "โปรโมชั่น",
    icon: "ph:gift-fill",
  },
  {
    key: "coupon",
    label: "คูปอง",
    icon: "mdi:coupon",
  },
  {
    key: "wheel",
    label: "กงล้อ",
    icon: "lucide:ferris-wheel",
  },
  {
    key: "mysteryBox",
    label: "กล่องสุ่ม",
    icon: "ph:archive-bold",
  },
  {
    key: "campaign",
    label: "แจกจัดหนัก",
    icon: "emojione-v1:trophy",
  },
];

const Item = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
  textAlign: "center",
  "& #icon": {
    color: theme.palette.primary.main,
    ...((theme.name === "preset3" ||
      theme.name === "preset5" ||
      theme.name === "preset6" ||
      theme.name === "preset12" ||
      theme.name === "preset13" ||
      theme.name === "preset15" ||
      theme.name === "preset17" ||
      theme.name === "preset25" ||
      theme.name === "preset27" ||
      theme.name === "preset34") && {
      color: "white",
    }),
    ...((theme.name === "preset23"
      || theme.name === "preset26") && {
      color: "black",
    }),
    ...((theme.name === "preset28") && {
      color: "#ffffffb3",
    }),
  },
  color: theme.palette.text.secondary,
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
    "& #icon-container": {
      background: theme.palette.primary.main,
      ...(theme.name === "preset14" && {
        background: "#790e50",
      }),
      ...((theme.name === "preset34" || theme.name === "preset35") && {
        background: theme.gradient[620],
      }),
    },
    "& #icon": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      ...(theme.name === "preset14" && {
        color: "#fff",
      }),
    },
    "& #lebel": {
      fontWeight: 600,
      color: theme.palette.primary.main,
      ...(theme.name === "preset5" && {
        color: "#86c01a",
      }),
      ...(theme.name === "preset14" && {
        color: "#fff",
      }),
    },
  },
}));

const StyledBox = styled(Box)<any>(({ theme, ismobile }) => ({
  boxShadow: theme.shadows[8],
  cursor: "pointer",
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  background: alpha(theme.palette.secondary.main, 0.6),
  ...((
    theme.name === "preset3" ||
    theme.name === "preset5" ||
    theme.name === "preset6" ||
    theme.name === "preset12" ||
    theme.name === "preset13" ||
    // theme.name === "preset15" ||
    theme.name === "preset16" ||
    theme.name === "preset25" ||
    theme.name === "preset27" ||
    theme.name === "preset34" ||
    theme.name === "preset35"
  ) && {
    background: theme.gradient[100],
    border: "none",
  }),
  ...(((theme.name === "preset23") || (theme.name === "preset15")) && {
    background: theme.gradient[500],
    border: "none",
  }),
  ...((theme.name === "preset26") && {
    background: theme.gradient[410],
    border: "none",
  }),
  ...(theme.name === "preset14" && {
    backgroundColor: "#fc8fb2",
  }),
  ...(theme.name === "preset17" && {
    background: theme.gradient[620],
    border: "none",
  }),
  ...(theme.name === "preset30" && {
    background: theme.gradient[921],
    border: "none",
  }),

  color: theme.palette.getContrastText(theme.palette.primary.main),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: ismobile === 'true' ? 48 : 64,
  width: ismobile === 'true' ? 48 : 64,
  borderRadius: 100,
  transition: "all 0.12s ease-in-out",
}));

export default function MainMenu() {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const router = useRouter();
  const { isUfaEnabled, isBetflixEnabled } = useFetchProvidersList();
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);

  // console.log('isUfaEnabled', isUfaEnabled)
  // console.log('isBetflixEnabled', isBetflixEnabled)

  const filteredMenus =
    isBetflixEnabled
      ? config?.cashback_type && config?.cashback_type === 'custom'
        ? menus.filter(
          (menu) => !["refund"].includes(menu.key)
        )
        : menus.filter(
          (menu) => !["campaign"].includes(menu.key)
        )
      : config?.cashback_type && config?.cashback_type === 'custom'
        ? menus.filter(
          (menu) => !["transferWallet", "wallets", "campaign", "refund"].includes(menu.key)
        )
        : menus.filter(
          (menu) => !["transferWallet", "wallets", "campaign", "cashbackTurnover", "cashbackWinlose"].includes(menu.key)
        )

  const handleItemClick = (key: string) => {
    if (key === "mystery-box") {
      router.push("/mystery-box");
    } else {
      setOpen(true);
      setData(key);
    }
  };

  return (
    <Stack spacing={2}>
      <Divider />
      <Grid
        className="main-menu"
        container
        m={isMobile ? '0 !important' : 'auto'}
        sx={{
          background: (theme) => theme.gradient[950], // พื้นหลังเมนู
          justifyContent: { xs: "center", md: "space-between", lg: "space-between" },
          borderRadius: "16px",
        }}
      >
        {filteredMenus.map((menu) => (
          <Grid
            onClick={() => handleItemClick(menu.key)}
            key={menu.key}
            item
            xs={filteredMenus ? (12 / filteredMenus.length) : 2}
            md={1}
          >
            <Item gap={0.5}>
              <StyledBox id="icon-container" ismobile={isMobile.toString()}>
                <Icon
                  id="icon"
                  icon={menu?.icon || ""}
                  width={36}
                  height={36}
                />
              </StyledBox>
              <Typography
                id="lebel"
                fontWeight={"500"}
                variant="button"
                sx={{
                  fontSize: isMobile ? '0.5rem' : 'initial',
                  color: (theme) => theme.gradient[920]
                }}
              >
                {menu.label}
              </Typography>
            </Item>
          </Grid>
        ))}
      </Grid>
      <Divider />
    </Stack>
  );
}
